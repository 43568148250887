import React, {useState, useEffect} from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer'
import { getTagsByCategory } from '../api/tag';
import { getRecipes, searchRecipes } from '../api/recipe';


function SearchPage() {

    const [cuisineTags, setCuisineTags] = useState([]);
    const [restrictionTags, setRestrictionTags] = useState([]);
    const [foodTags, setFoodTags] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [cboxes, setCboxes] = useState({});
    const [recipes, setRecipes] = useState([]);

    let handleTags = ({ target }) => {
        let newCboxes = {...cboxes};
        newCboxes[target.value] = target.checked;
        setCboxes(newCboxes);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        searchRecipes(setRecipes, keyword, cboxes);
    }

    useEffect(() => {
            getTagsByCategory(setCuisineTags, "cuisine");
            getTagsByCategory(setRestrictionTags, "restriction");
            getTagsByCategory(setFoodTags, "food");
            getRecipes(setRecipes);
    }, []);

    return (
        <div class="dark:bg-gray-950 dark:text-white">
            <Header/>

            {/* search block */}
            <div class="dark:bg-gray-950 dark:text-white flex justify-center mt-5">
                <div class="w-1/2">

                    <div class="mt-5 text-center">
                        <h1 class="font-bold text-2xl font-lexend dark:text-white">SEARCH FOR A RECIPE</h1> 
                    </div>
                    
                    <div class="flex justify-left mt-5">
                    <form class="w-full">   
                        <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white flex">Search</label>
                        <div class="relative">
                            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none flex">
                                <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input 
                                type="search" 
                                id="keyword"
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)} 
                                class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                placeholder="What do you want to cook?"
                                required />
                        </div>
                        
                    </form>
                    <button type="submit" onClick={handleSubmit} class="ml-3 text-white bg-indigo-950 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:focus:ring-blue-800">Search</button>
                    </div>
                </div>
            </div>
            {/* end search block */}


            <div class="grid-cols-6 grid">

            {/* start filters */}
                <div class="ml-5 col-span-1 mt-10">
                    {/* <h1 class="font-bold text-2xl font-lexend dark:text-white">FILTERS</h1>  */}

                    <h1 class="text-md w-1/2 font-lexend font-bold dark:text-white">Cuisine</h1> 

                    <div class="w-1/2 mb-4 mt-3">
                    
                        {cuisineTags.map((element, index) => (
                            <div class="w-full">
                                <input 
                                    id={element.title} 
                                    checked={cboxes[element.title]}
                                    onClick={handleTags}
                                    type="checkbox" 
                                    value={element.title} 
                                    class="w-4 h-4 mb-3 text-blue-600 bg-gray-100 border-gray-300 rounded-sm focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{String(element.title).charAt(0).toUpperCase() + String(element.title.slice(1))}</label>
                            </div>
                        ))}
                    </div>

                    <h1 class="text-md w-1/2 font-lexend dark:text-white mt-3">Food Type</h1> 

                    <div class="w-1/2 mb-4 mt-3">
                    
                        {foodTags.map((element, index) => (
                            <div class="w-full">
                                <input 
                                    id={element.title} 
                                    checked={cboxes[element.title]}
                                    onClick={handleTags}
                                    type="checkbox" 
                                    value={element.title} 
                                    class="w-4 h-4 mb-3 text-blue-600 bg-gray-100 border-gray-300 rounded-sm focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{String(element.title).charAt(0).toUpperCase() + String(element.title.slice(1))}</label>
                            </div>
                        ))}
                    </div>

                    <h1 class="text-md w-1/2 font-lexend dark:text-white mt-3">Diets/Restrictions</h1> 

                    <div class="w-1/2 mb-4 mt-3">
                    
                        {restrictionTags.map((element, index) => (
                            <div class="w-full">
                                <input 
                                    id={element.title} 
                                    checked={cboxes[element.title]}
                                    onClick={handleTags}
                                    type="checkbox" 
                                    value={element.title} 
                                    class="w-4 h-4 mb-3 text-blue-600 bg-gray-100 border-gray-300 rounded-sm focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{String(element.title).charAt(0).toUpperCase() + String(element.title.slice(1))}</label>
                            </div>
                        ))}
                    </div>
                </div>

                {/* end filters */}
                
                <div class="max-md:mx-5 h-fit col-span-5">
                    
                    <div class="max-md:mx-3">
                        <div class="max-w-screen-xl w-screen relative flex items-center mt-10">
                            <h1 class="font-bold text-2xl font-lexend dark:text-white">Results</h1> <div class="ml-5 flex-grow border-t border-1 border-indigo-950 dark:border-indigo-800"></div>
                        </div>
                    </div>

                    <div class="w-screen grid md:grid-cols-3 max-md:grid-cols-1 max-w-screen-xl justify-self-center mt-5 gap-x-3 gap-y-10">
                            {recipes.map((element, index) => (
                            
                            <div>

                                <figure class="relative transition-all duration-300 cursor-pointer filter md:h-96 h-48 max-md:h-96 w-full col-span-1">
                                    <a href={"recipe/" + element.title}>
                                        <img class="h-full w-full" src={element.img} alt={element.title}/>
                                        <figcaption class="absolute text-lg text-black">
                                            <p class="text-indigo-800 hover:underline text-lg font-lexend dark:text-indigo-300">{element.title}</p>
                                        </figcaption>
                                    </a>
                                </figure>


                            </div>
                        ))}
                        
                    </div>
                </div>

                
        
            </div>
            
        <Footer/>
        </div>
  )
}

export default SearchPage